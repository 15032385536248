<template>
    <a-card>
        <a-page-header
            :title='"组织绑定状态"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
            :rules='rules'
        >
            <a-form-model-item label="广告主">
                {{ form.advertiserName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="组织名和ID">
                {{ form.oceanOrgName || '-' }} {{ form.oceanOrgId || '-' }}
            </a-form-model-item>
            <a-form-model-item label="绑定状态" prop="bindStatus">
                <a-radio-group v-model="form.bindStatus">
                    <a-radio :value="2">待绑定</a-radio>
                    <a-radio :value="3">人工绑定</a-radio>
                    <a-radio :value="4">取消绑定</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            form: {
                bindStatus: ''
            },
            rules: {
                bindStatus: [
                    { required: true, message: '请选择绑定状态', trigger: 'change' }
                ],
            },
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 18 }
            },
            isSubmit: false,
        }
    },
    created() {
        if(this.id != 0) {
            this.getDetail()
        }
    },
    methods: {
        getDetail() {
            this.$api.core.pphtManage.getAdvertiserOauthDetail(this.id).then((res) => {
                if(res.code == 200) {
                    let _data = res.data
                    Object.assign(this.form, _data)

                    this.form.bindStatus = _data.bindStatus == 2 || _data.bindStatus == 3 || _data.bindStatus == 4 ? _data.bindStatus : ''
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/pphtManage/authorizeManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    this.$api.core.pphtManage.updateAdvertiserOrgBindStatus( this.form ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/pphtManage/authorizeManage'
                            })
                        } else {
                            this.$message.error('提交失败')
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>